import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
            path: '/dashboard/:any',
            name: 'clientcenter/dashboard/any',
            component: () =>
                import ('@/views/Home.vue'),

        },
        {
            path: '/dashboard',
            name: 'clientcenter/dashboard',
            component: () =>
                import ('@/views/Home.vue'),

        },
        {
            path: '/tasks-list',
            name: 'clientcenter/tasks-list',
            component: () =>
                import ('@/views/TasksList.vue'),
            meta: {
                pageTitle: 'Tasks List',
                breadcrumb: [{
                    text: 'Tasks List',
                    active: true,
                }, ],
            },
        },
        {
            path: '/add-task',
            name: 'clientcenter/add-task',
            component: () =>
                import ('@/views/AddTask.vue'),
            meta: {
                pageTitle: 'Add Task',
                breadcrumb: [{
                    text: 'Add Task',
                    active: true,
                }, ],
            },
        },
        {
            path: '/your-reviews',
            name: 'clientcenter/your-reviews',
            component: () =>
                import ('@/views/YourReviews.vue'),
            meta: {
                pageTitle: 'Your Reviews',
                breadcrumb: [{
                    text: 'Your Reviews',
                    active: true,
                }, ],
            },
        },
        /**
         * I have commented this bunch of code regarding task #108809
        {
            path: '/services',
            name: 'clientcenter/services',
            component: () =>
                import ('@/views/Services.vue'),
            meta: {
                pageTitle: 'Your Services',
                breadcrumb: [{
                    text: 'Your Services',
                    active: true,
                }, ],
            },
        }, */
        /* {
            path: '/results-dashboard',
            name: 'clientcenter/results-dashboard',
            redirect: to => {
                return 'profile'
            },

        }, */
        {
            path: '/',
            name: 'clientcenter/',
            component: () =>
                import ('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import ('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})
router.beforeEach((to, from, next) => {
    if (to.name === 'clientcenter/your-reviews') {
        if (window.DATAGLOBLE.isBrightLocalArray > 0) {
            next(); // Allow navigation
        } else {
            next(false); // Block navigation
        }
    } else {
        next(); // Allow navigation for other routes
    }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router